.company-we{
    text-align: center;
    padding:0 0 70px 0;
    .title{
        font-size:36px;
        margin-bottom:30px;
    }
    .cont{
        font-size:16px;
        line-height:28px;
        color:#666;
        padding:0 165px;
        position: relative;
        .u-icon{
            position: absolute;
            width:30px;height:28px;
            &.u-icon-1{
                top:0;
                left:0;
                background:url("@{imgurl}subsidiary/yh-l.png") no-repeat center center;
            }
            &.u-icon-2{
                bottom:0;
                right:0;
                background:url("@{imgurl}subsidiary/yh-r.png") no-repeat center center;

            }
        }
    }
}

//company-info{
.company-info{
    background:url("@{imgurl}subsidiary/sec-num-bg.jpg") no-repeat center center;
    background-size:auto 100%;
    min-height:610px;
    padding:75px 0 50px 0;
    color:#FFF;
    margin-bottom:50px;
    .title{
        font-size:36px;
        margin-bottom:30px;
    }
    .cont{
        font-size:16px;
        line-height:28px;
        margin-bottom:60px;
    }
    .data-box{
        margin-bottom:60px;
    }
    .c-data{
        padding:30px 0;
        text-align: center;
        background:#FFF;
        color:#000;
        .txt1{
            .n{
                font-size:90px;
                color:#1a1a1a;
                margin-right:10px;
            }
            .u{
                font-size:30px;
            }
        }
        .txt2{
            font-size:16px;
        }
    }
    .time{
        border-top:1px solid #ccc;
        font-size:14px;
        color:#999;
        padding-top:20px;
    }
}

//company-content
.company-content{
    margin-bottom:50px;
    .title{
        font-size:36px;
        margin-bottom:30px;
    }
    .cont{
        font-size:16px;
        line-height:36px;
    }
}
//company-qxgs
.company-qxgs{
    background:#fafafa;
    padding:55px 0;
    .title{
        font-size:36px;
        margin-bottom:50px;
    }
    .col-md-1-5{
        margin-bottom:30px;
    }
    .cbox{
        border:1px solid #ccc;
        font-size:16px;
        text-align: center;
        padding:50px 20px 20px 20px;
        .img{
            img{
                width:100%;
                max-width: 600px;
            }
        }
        .name{
            font-size:14px;
            color:#666;
            height:65px;
            line-height:22px;
            overflow: hidden;
            text-overflow:ellipsis; 
            display: -webkit-box;
            -webkit-line-clamp:3;
            -webkit-box-orient:vertical;
        }
        &:hover{
            box-shadow: 0px 3px 15px 0px #ccc;
        }

    }
}
//company-row-img
.company-row-img{
    margin-bottom:40px;
    .imgbox{
        width:33.333333%;
        float: left;
        img{
            width:100%;
        }
    }
}

//company-p2s{
.company-p2s{
    background:#eee url("@{imgurl}subsidiary/p2s.png") no-repeat center top;
    background-size:auto 100%;
    min-height:500px;
    padding:120px 0 50px 0;
    .data-box{
        .col-md-4{
            margin-bottom:30px;
        }
        .cbox{
            text-align: center;
            background:#FFF;
            color:#000;
            .img{
                padding:70px;
                img{
                    width:100px;
                    height:100px;
                }
            }
            .name{
                font-size:18px;
                border-top:1px solid #eee;
                padding:15px 0;
            }
        }
        .btnbox{
            margin-top:40px;
            text-align: center;
            position: relative;
            height:45px;
            .swiper-button-next,
            .swiper-button-prev{
                margin:0;;
                position: static;
                background:none;
                display: inline-block;
                width:45px;
                height:45px;
                border:1px solid #3cb1ad;
                border-radius: 50%;
                top:55px;
                text-align: center;
                line-height:45px;
                span{
                    color:#3cb1ad;
                }
                &:hover{
                    border-color:#3cb1ad;
                }
            }
            .swiper-button-next{
        
            }
            .swiper-button-prev{
                margin-right:20px;
            }
        }
    }
    
}
    
//company-dev
.company-dev{
    padding-bottom:140px;
    text-align: center; 
    .title{
        font-size:36px;
        margin-bottom:30px;
    }
	.thumbnail {
		padding: 0;
		border:1px solid #eee;;
		background:none;
		border-radius:0;
		position: relative;
		.img {
            width:100%;
            margin-bottom:50px;
            img{
                width:100%;
            }
        }
        .hr-line{
            background:#000;
            width:50px;
            margin:20px auto;
        }
	
		.caption {
			width:100%;
            padding:0 20px 20px 20px;
            position: relative;
            .year{
                .y{
                    font-family: ArialNarrow-Bold;
                    font-size: 36px;
                    margin-right:10px;
                    vertical-align: middle;
                }
                .u{
                    font-size: 18px;
                    vertical-align: middle;
                }
            }
			.tith{
                font-size:16px;
                height:50px;
                line-height:24px;
                overflow: hidden;
			}
		}
    }
    
    .btnbox{
        margin-top:40px;
        text-align: center;
        position: relative;
        height:45px;
        .swiper-button-next,
        .swiper-button-prev{
            margin:0;;
            position: static;
            background:none;
            display: inline-block;
            width:45px;
            height:45px;
            border:1px solid #3cb1ad;
            border-radius: 50%;
            top:55px;
            text-align: center;
            line-height:45px;
            span{
                color:#3cb1ad;
            }
            &:hover{
                border-color:#3cb1ad;
            }
        }
        .swiper-button-next{
    
        }
        .swiper-button-prev{
            margin-right:20px;
        }
    }
    .btnmore{
        margin-top:50px;
        .btn{
            padding:10px 60px;
        }
    }

}
    
//company-honor
.company-honor{
    padding-bottom:140px;
    text-align: center; 
 
	.thumbnail {
		padding: 0;
		border:6px solid #f8e8db;;
		background:none;
		border-radius:0;
		position: relative;
		.img {
            width:100%;
            padding:20px;
            img{
                width:100%;
            }
        }
        .caption {
			width:100%;
            padding:0 20px 20px 20px;
            position: relative;
			.tith{
                font-size:14px;
                height:50px;
                line-height:24px;
                overflow: hidden;
			}
		}
    }
    
    .btnbox{
        margin-top:40px;
        text-align: center;
        position: relative;
        height:45px;
        .swiper-button-next,
        .swiper-button-prev{
            margin:0;;
            position: static;
            background:none;
            display: inline-block;
            width:45px;
            height:45px;
            border:1px solid #3cb1ad;
            border-radius: 50%;
            top:55px;
            text-align: center;
            line-height:45px;
            span{
                color:#3cb1ad;
            }
            &:hover{
                border-color:#3cb1ad;
            }
        }
        .swiper-button-next{
    
        }
        .swiper-button-prev{
            margin-right:20px;
        }
    }

}


// 1600px以下屏幕
@media (max-width:@screen-lg) {

}

@media (max-width:@screen-sm) {
    .company-we{
        padding:0 0 35px 0;
        .title{
            font-size:18px;
            margin-bottom:15px;
        }
        .cont{
            font-size:14px;
            line-height:26px;
            padding:0 15px;
            .u-icon{
                width:20px;
                height:18px;
                &.u-icon-1{
                    top:0;
                    left:-10px;
                    background-size:100% auto;
                }
                &.u-icon-2{
                    bottom:0;
                    right:-10px;
                    background-size:100% auto;
                }
            }
        }
    }
    //company-info{
    .company-info{
        background-size:auto 100%;
        height:auto;
        padding:25px 0 20px 0;
        margin-bottom:30px;
        .title{
            font-size:18px;
            margin-bottom:15px;
        }
        .cont{
            font-size:12px;
            line-height:21px;
            margin-bottom:30px;
        }
        .data-box{
            margin-bottom:0;
            .col-md-4{
                margin-bottom:20px;
            }
        }
        .c-data{
            padding:15px 0;
            .txt1{
                .n{
                    font-size:45px;
                    margin-right:10px;
                }
                .u{
                    font-size:16px;
                }
            }
            .txt2{
                font-size:14px;
            }
        }
        .time{
            font-size:14px;
            padding-top:20px;
        }
    }

    //company-content
    .company-content{
        margin-bottom:25px;
        .title{
            font-size:18px;
            margin-bottom:15px;
        }
        .cont{
            font-size:14px;
            line-height:26px;
        }
    }
    
    //company-qxgs
    .company-qxgs{
        padding:20px 0;
        .title{
            font-size:18px;
            margin-bottom:20px;
        }
        .col-md-1-5{
            margin-bottom:15px;
        }
        .cbox{
            font-size:14px;
            padding:20px 20px 20px 20px;
            .img{
                height:auto;
                margin-bottom:15px;
                img{
                    width:80%;
                    height:auto;
                }
            }
            .name{
                font-size:14px;
                height:auto;
                line-height:21px;
            }
        }
    }
    //company-row-img
    .company-row-img{
        margin-bottom:0;
        .imgbox{
            width:100%;
            float: none;
            margin-bottom:15px;
            img{
                width:100%;
            }
        }
    }


    //company-p2s{
    .company-p2s{
        min-height:auto;
        padding:85px 0 35px 0;
        .data-box{
            .col-md-4{
                margin-bottom:30px;
            }
            .cbox{
                .img{
                    padding:35px;
                }
                .name{
                    font-size:14px;
                    padding:15px 0;
                }
            }
        }
    }
    //company-dev
    .company-dev{
        padding-bottom:50px;
        .title{
            font-size:18px;
            margin-bottom:15px;
        }
        .thumbnail {
            .img {
                width:100%;
                margin-bottom:25px;
                img{
                    width:100%;
                }
            }
            .hr-line{
                width:50px;
                margin:15px auto;
            }
            .caption {
                width:100%;
                padding:0 15px 15px 15px;
                .year{
                    .y{
                        font-size:20px;
                        margin-right:10px;
                    }
                    .u{
                        font-size:14px;
                    }
                }
                .tith{
                    font-size:14px;
                    height:50px;
                    line-height:24px;
                  
                }
            }
        }
        
        .btnbox{
            margin-top:20px;
        }
    }

    //company-honor
    .company-honor{
        padding-bottom:40px;
   
        .thumbnail {
            .img {
                width:100%;
                padding:15px;
                img{
                    width:100%;
                }
            }
            .caption {
                width:100%;
                padding:0 20px 20px 20px;
                position: relative;
                .tith{
                    font-size:14px;
                    height:50px;
                    line-height:24px;
                }
            }
        }
        .btnbox{
            margin-top:20px;
        }
    }
}


@media only screen  and (min-device-width : 768px)  and (max-device-width : 1024px)  and (orientation : portrait){
    //company-qxgs
    .company-qxgs{
        .cbox{
            padding:20px;
            .img{
                height:auto;
                margin-bottom:20px;
                img{
                    width:100%;
                }
            }
        }
    }
}
    
    