//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

@gray-base:              #000;
@gray-darker:            lighten(@gray-base, 13.5%); // #222
@gray-dark:              lighten(@gray-base, 20%);   // #333
@gray:                   lighten(@gray-base, 33.5%); // #555
@gray-light:             lighten(@gray-base, 46.7%); // #777
@gray-lighter:           lighten(@gray-base, 93.5%); // #eee

@blue:                  #0065b2;
@link-blue:             #5bc0de;

@brand-primary:         darken(#428bca, 6.5%); // #337ab7
@brand-success:         #5cb85c;
@brand-info:            #5bc0de;
@brand-warning:         #f0ad4e;
@brand-danger:          #d9534f;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               #fff;
//** Global text color on `<body>`.
@text-color:            @gray-dark;

//** Global textual link color.
@link-color:            @brand-primary;
//** Link hover color set via `darken()` function.
@link-hover-color:      darken(@link-color, 15%);


@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;
@font-family-fzqianhei:  "fzlantingqianheijianti","Helvetica Neue", Helvetica, Arial, sans-serif;

//** @icon-img-path.
@imgurl:                   "../images/";
@screen-lg:                1600px;
@screen-sm:                767px;
// 大屏字体
// .fs-lg(@px) {
//     font-size: unit(@px * 0.8, px);
// }
.opacity(@number){
    /* older safari/Chrome browsers */
    -webkit-opacity: @number/100;
    /* Netscape and Older than Firefox 0.9 */
    -moz-opacity: @number/100;
    /* Safari 1.x (pre WebKit!) 老式khtml内核的Safari浏览器*/
    -khtml-opacity: @number/100;
    /* IE9 + etc...modern browsers */
    opacity: @number/100;
    /* IE 4-9 */
    filter:alpha(opacity=@number);
    /*This works in IE 8 & 9 too*/
    -ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=@number);
    /*IE4-IE9*/
    filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=@number);
 }


