// Default navbar
.navbar-default2 {
	margin-bottom: 70px !important;
  	background-color:#FFF;
	border-color: #e6e6e6;
    .navbar-brand {
		display: none;
		color: #000;
		&:hover,
		&:focus {
			color: #0068b7;
		}
		@media (max-width: @screen-sm) {
			display: block;
		}
    }
  
    .navbar-text {
		color:#000;
    }
  
    .navbar-nav {
		text-align: center;
		float:none;
		li{
			display: inline-block;
			text-align: center;
			margin:0 20px;
		}
      > li > a {
			color: #000;
			&:hover,
			&:focus {
			color: #0068b7;
			background-color: #FFF;
			}
   		}
      > .active > a {
			&,
			&:hover,
			&:focus {
				color: #0068b7;
				border-bottom:1px solid #0068b7;
			}
     	}
		// Uncollapse the nav
		@media (max-width: @screen-sm) {
			float: none;
			margin: 0;
			> li {
			float: none;
			display:block;
				> a {
					padding-top:    10px;
					padding-bottom: 10px;
				}
			}
		}
		// Uncollapse the nav
		@media (min-width: @screen-sm) {
		float: none;
		margin: 0;
		> li {
			float: none;
				> a {
				padding-top:    25px;
				padding-bottom: 25px;
				}
			}
		}
    }
  
    .navbar-toggle {
      border-color:#ccc;
      &:hover,
      &:focus {
        background-color: #eee;
      }
      .icon-bar {
        background-color:#eee;;
      }
    }
  
    .navbar-collapse,
    .navbar-form {
      border-color: #e6e6e6;
    }
  
    // Dropdown menu items
    .navbar-nav {
      // Remove background color from open dropdown
      > .open > a {
        &,
        &:hover,
        &:focus {
          color: #0068b7;
        }
      }
    }
  
  
    // Links in navbars
    //
    // Add a class to ensure links outside the navbar nav are colored correctly.
  
    .navbar-link {
      color: #000;
      &:hover {
        color: #0068b7;
      }
    }
  
    .btn-link {
      color: #000;
      &:hover,
      &:focus {
        color: #0068b7;
      }
    }
}

// 1600px以下屏幕
@media (max-width:@screen-lg) {

}

@media (max-width:@screen-sm) {
	.navbar-default2 {
		border:none;
		border-bottom: 1px solid #e6e6e6;
		margin-bottom:35px !important;
		.navbar-nav {
			li{
				margin:0;
			}
		}
	}
}




