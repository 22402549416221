.th-company-banner {
    position: relative;
    height:400px;
	.banner-box{
		overflow: hidden;
		position: relative;
	}
	.pic {
		height: 100%;
		width: 100%;
		&:after {
			padding-top: 31.25%;
		}
		img {
			position: absolute;
			display: block;
			width: 100%;
		}
	}
	.con {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		font-size: 0;
		width: 100%;
		height: 100%;
		&:after {
			display: inline-block;
			height: 100%;
			content: "";
			vertical-align: middle;
			width: 0;
		}
	}
	.banner-text {
		display:inline-block;
		width:100%;
		vertical-align: middle;
		.title {
			font-size: 24px;
			line-height: 1.38;
			overflow: hidden;
			margin: 0;
			padding-top: 9px;
			text-align: inherit;
			padding-bottom: 0;
			color: inherit;
			color:#FFF;
			font-family:@font-family-fzqianhei;
		}
		.en-title {
			font-size: 16px;
			line-height: 1.375;
			margin-bottom: -7px;
			overflow: hidden;
			color:#FFF;
		}
	}
}



// 1600px以下屏幕
@media (max-width:@screen-lg) {

	// th-company-banner
	.th-company-banner {
		.banner-text {
			.title {
				font-size: 20px;
			}
			.en-title {
				font-size: 14px;
			}
		}
	}
	
}

@media (max-width:@screen-sm) {
	// th-company-banner
	.th-company-banner {
		margin-bottom:20px;
		.banner-text {
			padding:0 20px;
			.title {
				font-size:18px;
			}
			.en-title {
				font-size: 14px;
			}
		}
	}
}




