.th-data-box{
    border-bottom:2px solid #f8f8f8;
    padding-bottom:40px;
    margin-bottom: 30px;
    .c-data{
        padding:30px 0;
        text-align: center;
        background:#f8f8f8;
        .txt1{
            .n{
                font-size:48px;
                color:#1a1a1a;
                margin-right:10px;
            }
            .u{
                font-size:16px;
            }
        }
        .txt2{
            font-size:16px;
        }
    }
}

//company-pro1{
.company-pro1{
     .cbox{
        border:1px solid #eaeaea;
        text-align: center;
        background:#FFF;
        color:#000;
        overflow: hidden;
        margin-bottom:30px;
        .img{
            height:262px;
            text-align: center;
            img{
                width:100%;
                height:262px;
            }
        }
        .name{
            font-size:16px;
            border-top:1px solid #eee;
            padding:15px 0;
            height:65px;
            color:#333;
            a{
                color:#333;
            }
        }
    }
    &.company-pro1-first{
        .cbox{
            .img{
                height:auto;
                img{
                    height:auto;
                }
            }
        }
    }
    &.company-pro1-list{
        .cbox{
            .img{
                height:345px;
                img{
                    height:345px;
                }
            }
        }
    }
}

//company-yb{
.company-yb{
    .cbox{
        margin-bottom:30px;
    }
    .cnt{
        border:1px solid #eaeaea;
        text-align: center;
        overflow: hidden;
        margin-bottom:10px;
        .img{
            height:252px;
            text-align: center;
            img{
                width:100%;
                height:252px;
            }
        }
        .name{
            font-size:16px;
            border-top:1px solid #eee;
            padding:15px 0;
            color:#333;
            a{
                color:#333;
            }
        }
    }
    .down{
        text-align: center;
        span{
            margin-left:10px;
        }
    }
}
   

// 1600px以下屏幕
@media (max-width:@screen-lg) {
    //company-pro1
    .company-pro1{
        .cbox{
            .img{
                height:210px;
                img{
                    height:210px;
                }
            }
            .name{
                font-size:14px;
            }
        }
        &.company-pro1-list{
            .cbox{
                .img{
                    height:293px;
                    img{
                        height:293px;
                    }
                }
            }
        }
    }

    //company-yb{
    .company-yb{
        .cnt{
            .img{
                height:214px;
                img{
                    height:214px;
                }
            }
        }
    }
        


}

@media (max-width:@screen-sm) {
    .th-data-box{
        padding-bottom:0;
        margin-bottom:15px;
        .col-md-4{
            margin-bottom:20px;
        }
        .c-data{
            padding:15px 0;
            .txt1{
                .n{
                    font-size:24px;
                    margin-right:10px;
                }
                .u{
                    font-size:14px;
                }
            }
            .txt2{
                font-size:14px;
            }
        }
    }
    
    //company-pro1{
    .company-pro1{
         .cbox{
            margin-bottom:15px;
            .img{
                height:auto;
                img{
                    width:100%;
                    height:auto;
                }
            }
            .name{
                font-size:14px;
                padding:15px 0;
                height:auto;
            }
        }
        &.company-pro1-first{
            .cbox{
                .img{
                    height:auto;
                    img{
                        height:auto;
                    }
                }
            }
        }
        &.company-pro1-list{
            .cbox{
                .img{
                    height:345px;
                    img{
                        height:345px;
                    }
                }
            }
        }
    }
    
    //company-yb{
    .company-yb{
        .cbox{
            margin-bottom:15px;
        }
        .cnt{
            margin-bottom:10px;
            .img{
                height:auto;
                img{
                    height:auto;
                }
            }
            .name{
                font-size:14px;
                padding:15px 0;
            }
        }
    }

}




