.sec-storageBanner {
    position: relative;
    height:786px;
	.banner-box{
		overflow: hidden;
		position: relative;
	}
	.pic {
		height: 100%;
		width: 100%;
		&:after {
			padding-top: 31.25%;
		}
		img {
			position: absolute;
			display: block;
			width: 100%;
		}
	}
	.banner-cont {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 50%;
		margin-left:-715px;
		height: 100%;
		font-size: 0;
		&:after {
			display: inline-block;
			height: 100%;
			content: "";
			vertical-align: middle;
			width: 0;
		}
	}
	.banner-text {
		display:inline-block;
		width:100%;
		vertical-align: middle;
		.img {
			overflow: hidden;
			margin: 0;
			padding-top: 9px;
			text-align: inherit;
			padding-bottom: 0;
			margin-bottom:25px;
		}
		.txt1 {
			font-size: 16px;
			line-height: 1.38;
			overflow: hidden;
			margin: 0;
			padding-top: 9px;
			text-align: inherit;
			padding-bottom:15px;
			color: inherit;
			color:#FFF;
			font-family:@font-family-fzqianhei;
		}
		.txt2 {
			font-size: 20px;
			line-height: 1.375;
			overflow: hidden;
			color:#FFF;
		}
	}
	.banner-nav{
		position: absolute;
		z-index:3;
		left:0;
		bottom:0;
		width:100%;
		background:url("@{imgurl}/bg-b-6.png");
		ul{
			list-style:none;
			padding:0;
			margin:0;
			li{
				list-style:none;
				padding:20px 0;
				float: left;
				width:25%;
				text-align: center;
				font-size:18px;
				.u-icon{
					width:64px;
					height:64px;
					margin-right:20px;
					background-position: center center;
					background-repeat:  no-repeat;
					&.u-icon-1{
						background-image:url("@{imgurl}subsidiary/sec-banner-ico1.png");
					}
					&.u-icon-2{
						background-image:url("@{imgurl}subsidiary/sec-banner-ico2.png");
					}
					&.u-icon-3{
						background-image:url("@{imgurl}subsidiary/sec-banner-ico3.png");
					}
					&.u-icon-4{
						background-image:url("@{imgurl}subsidiary/sec-banner-ico4.png");
					}
				}
				a{
					color:#FFF;
					display: block;
				}
				&.active{
				background-image: linear-gradient(90deg, #43c866 0%, #3397fb 100%), linear-gradient(#ffffff, #ffffff);
				background-blend-mode: normal, normal;
					.u-icon{
						&.u-icon-1{
							background-image:url("@{imgurl}subsidiary/sec-banner-ico1-hover.png");
						}
						&.u-icon-2{
							background-image:url("@{imgurl}subsidiary/sec-banner-ico2-hover.png");
						}
						&.u-icon-3{
							background-image:url("@{imgurl}subsidiary/sec-banner-ico3-hover.png");
						}
						&.u-icon-4{
							background-image:url("@{imgurl}subsidiary/sec-banner-ico4-hover.png");
						}
					}
				}
			}
		}
	}
}



// 1600px以下屏幕
@media (max-width:@screen-lg) {
	.sec-storageBanner {
		.pic {
			&:after {
				padding-top: 31.25%;
			}
		}
		.banner-cont {
			margin-left:-615px;
		}
		.banner-text {
			.img {
				padding-top: 9px;
				margin-bottom:15px;
				img{
					width:400px;
				}
			}
			.txt1 {
				font-size: 16px;
				padding-top: 9px;
				padding-bottom:10px;
			}
			.txt2 {
				font-size: 18px;
			}
		}
		.banner-nav{
			ul{
				li{
					padding:10px 0;
					font-size:15px;
					.u-icon{
						width:52px;
						height:52px;
						background-size:52px 52px;
					}
				}
			}
		}
	}
}

@media (max-width:@screen-sm) {
	// sec-storageBanner
	.sec-storageBanner {
		.banner-text {
			.title {
				font-size: 36px;
			}
			.en-title {
				font-size: 16px;
			}
		}
	}
}




