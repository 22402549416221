.r-contact{
    border: solid 5px #f8e8db;
    
    .tit{
        padding:30px 15px;
        border-bottom:2px solid #f8e8db;
        font-size:18px;
        .u-icon-c{
            width:40px;
            height:40px;
            background:url("@{imgurl}subsidiary/c-l-ico.png") no-repeat;
            margin-right:10px;
        }

    }
    .cont{
        padding:15px;
    }
    dl{
        padding:0;
        margin-bottom:0;
        dd{
            font-size:14px;
            line-height:32px;
            color:#343434;
        }
    }
}

// 1600px以下屏幕
@media (max-width:@screen-lg) {

	// r-contact
	.r-contact {
	
	}
	
}

@media (max-width:@screen-sm) {
    .r-contact{
        margin-top:30px;
        .tit{
            padding:15px 15px;
            font-size:16px;
            line-height:40px;
            .u-icon-c{
                width:30px;
                height:30px;
                margin-right:10px;
                background-size:100% auto;
            }
        }
        .cont{
            padding:15px;
        }
        dl{
            dd{
                font-size:12px;
                line-height:26px;
            }
        }
    }
}




